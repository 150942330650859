import React, { useContext, useState, useRef, useEffect } from "react"
import moment from "moment"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import axios from "axios"
import {
  GATSBY_GET_ORDER_DETAILS_WEBHOOK,
  GATSBY_UPLOAD_PROOF_OF_PAYMENT_WEBHOOK,
} from "gatsby-env-variables"

import Layout from "../Layout/Layout"
import Section from "../Elements/Section"
import Container from "../Layout/Container"
import ActionButtons from "../Elements/ActionButtons"
import UploadGuidelines from "../Upload/UploadGuidelines"
import UploadDropzone from "../Upload/UploadDropzone/index"
import useProofOfPaymentIcon from "./hooks/useProofOfPaymentIcon"
import DocumentsCardRow from "../Cart/DocumentsCard/DocumentsCardRow"
import ProofOfPaymentBankDetails from "./components/ProofOfPaymentBankDetails"
import UploadErrorNotification from "../Upload/Dropzone/UploadErrorNotification"
import ProofOfPaymentPersonalDetails from "./components/ProofOfPaymentPersonalDetails"
import Loading from "../Elements/Loading"

import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "services/zendeskService"
import { AppContext } from "../../context/AppContext"
import guidelines from "./utils/uploadGuidelines.json"
import { isIeOrEdge } from "../../services/browserCompatibility"
import { uploadDocument } from "../Upload/services/uploadDocuments"
import { b64toBlob, zendeskApi } from "../../services/zendeskService"
import { ProofOfPaymentContext } from "./ProofOfPaymentContext/ProofOfPaymentContext"

const MAX_FILE_SIZE_IN_BYTES = 3145728
const MAX_FILE_COUNT = 3
const isFullSize = false

const ProofOfPayment = ({ location }) => {
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const urlSearchParams = new URLSearchParams(location.search)
  const zendeskId = urlSearchParams.get("z") || ""
  const { proofOfPaymentState, proofOfPaymentDispatch } = useContext(
    ProofOfPaymentContext
  )
  const [filesUploaded, setFilesUploaded] = useState(documents)
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef(null)
  const data = useProofOfPaymentIcon()
  const proofOfPaymentIcon = data?.document?.childImageSharp?.fixed
  const [orderDetails, setOrderDetails] = useState(null)
  const [isWithin12Hours, setIsWithin12Hours] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(true)

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    let documentsWithNoToken =
      filesUploaded?.filter?.((document) => !document?.token)?.length || 0

    if (!state?.activeDocument && documentsWithNoToken > 0) {
      let currentDocumentWithNoToken = filesUploaded?.find?.(
        (document) => !document?.token
      )
      dispatch({
        type: "SAVE_ACTIVE_DOCUMENT",
        payload: currentDocumentWithNoToken,
      })
      uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
    }
  }, [filesUploaded])

  const uploadToZendesk = async ({ currentDocument }) => {
    let documentsWithToken =
      filesUploaded?.filter?.((document) => !!document?.token)?.length + 1

    const UPLOADED_FILES = await zendeskUploadFiles([currentDocument])
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)

    let newTempDocument = {
      ...currentDocument,
      token: GENERATED_FILE_TOKEN?.[0],
    }

    let currentDocuments = [...state.documents]
    currentDocuments = currentDocuments.filter(
      (document) => document?.oldname !== newTempDocument?.oldname
    )
    currentDocuments = [...currentDocuments, newTempDocument]
    setFilesUploaded(currentDocuments)

    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })

    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Successfully uploaded ${documentsWithToken} file${
          documentsWithToken > 1 ? "s" : ""
        }`,
        color: "success",
      },
    })
  }

  // dispatch anytime files uploaded changes
  useEffect(() => {
    proofOfPaymentDispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...filesUploaded],
    })
  }, [filesUploaded])

  useEffect(() => {
    const getOrderDetails = async () => {
      let response = await axios.post(GATSBY_GET_ORDER_DETAILS_WEBHOOK, {
        zendeskId,
      })

      setOrderDetails(response?.data || null)
    }

    const getZendeskVerificationTicket = async () => {
      let query = `MedGrocer Verification: ${zendeskId}`
      // query = encodeURI(query)
      let response = await axios.post(
        "https://hook.us1.make.com/l8tmb4ra3dhxsdwlbzzjdwpgpfp1dcf6",
        {
          query,
        }
      )

      if (response?.data) {
        let ticketDate = response?.data?.[0]?.created_at

        let deadline = moment(ticketDate).add(12, "hours")
        let currentDateTime = moment()
        let isWithin12Hours = currentDateTime.isBefore(deadline)

        setIsWithin12Hours(isWithin12Hours)
      }

      setIsDataLoading(false)
    }

    if (!!zendeskId) {
      getOrderDetails()
      getZendeskVerificationTicket()
    }
  }, [zendeskId])

  const handleDeleteDocument = (index) => {
    const { documents } = proofOfPaymentState
    let documentsList = [...documents]
    documentsList.splice(index, 1)
    proofOfPaymentDispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...documentsList],
    })

    let tempFilesUploaded = [...filesUploaded]
    tempFilesUploaded.splice(index, 1)
    setFilesUploaded(tempFilesUploaded)
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = (setNotifications) => (file) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const handleFileRead = (event) => {
    const tempFilesUploaded = [...event.target.files]
    let initialNotifications = []
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES)
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            "pop",
            setFilesUploaded,
            null,
            handleAlreadyUploaded(setNotifications)
          )
        else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 4MB. Please upload a file or photo less than 4MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  const handleSubmitProofOfPayment = async () => {
    setLoading(true)
    let filesUploaded = await Promise.all(
      proofOfPaymentState?.documents?.map(async (document) => {
        const b64Data = document.path.split(",")[1]
        const contentType = document.path.split(",")[0].match(/:(.*?);/)[1]

        const fileResponse = await zendeskApi().post(
          `/uploads.json?filename=${document.filename}`,
          b64toBlob(b64Data, contentType),
          { headers: { "Content-Type": "application/binary" } }
        )

        return {
          ...fileResponse?.data?.upload?.attachment,
          token: fileResponse?.data?.upload?.token,
        }
      })
    )
    await axios.post(GATSBY_UPLOAD_PROOF_OF_PAYMENT_WEBHOOK, {
      documents: filesUploaded,
      uploadTokens: filesUploaded?.map((fileUploaded) => fileUploaded.token),
      zendeskId,
    })
    setLoading(false)
    navigate("/epharmacy/proof-of-payment/complete")
    // upload files to airtable and zendesk
  }

  return (
    <Layout
      title="Payment Form"
      seoTitle="Payment Form"
      subtitle={
        !isDataLoading &&
        isWithin12Hours &&
        `Take a photo or upload a scanned copy of the payment confirmation from your bank.`
      }
    >
      <Container isCentered>
        {isDataLoading ? (
          <div className="is-flex justify-center">
            <Loading size={25} type="circle" />
          </div>
        ) : (
          <div>
            <ProofOfPaymentBankDetails isWithin12Hours={isWithin12Hours} />

            {isWithin12Hours && (
              <Formik
                initialValues={{ ...proofOfPaymentState }}
                onSubmit={handleSubmitProofOfPayment}
              >
                {() => (
                  <Form>
                    <ProofOfPaymentPersonalDetails
                      orderDetails={orderDetails}
                    />
                    <Section
                      title="Upload Proof Of Payment"
                      className="mt-1 mb-4"
                    >
                      <UploadGuidelines guidelines={guidelines} />
                      {filesUploaded.length < 3 ? (
                        <UploadDropzone
                          fileInputRef={fileInputRef}
                          handleFileChooser={openFileChooser}
                          filesUploaded={filesUploaded}
                          handleFileRead={handleFileRead}
                          notifications={notifications}
                          icon={proofOfPaymentIcon}
                          label="Upload Proof of Payment"
                        />
                      ) : null}
                      <div className="my-1">
                        {filesUploaded?.map((file, index) => (
                          <DocumentsCardRow
                            fileName={file.name}
                            oldFileName={file.oldname}
                            index={index}
                            handleDeleteDocument={handleDeleteDocument}
                            file={file}
                            isFullSize={isFullSize}
                            showOldFileName
                            showDeleteButton
                            isClickable
                          />
                        ))}
                      </div>
                    </Section>
                    <ActionButtons
                      submit={{
                        label: "Submit",
                        loading,
                        disabled: proofOfPaymentState?.documents?.length < 1,
                      }}
                    />
                  </Form>
                )}
              </Formik>
            )}
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default ProofOfPayment
