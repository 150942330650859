import React, { useContext, Fragment } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import { GATSBY_GET_ORDER_DETAILS_WEBHOOK } from "gatsby-env-variables"

import Loading from "elements/Loading"
import { isBrowser } from "services/general"
import { hasSignedInUser } from "../Auth/services/user"

import { AuthContext } from "context/AuthContext"

const PrivateRoute = ({ isPrivate, children, location }) => {
  const { authDispatch } = useContext(AuthContext)
  const urlSearchParams = new URLSearchParams(location.search)
  const zendeskId = urlSearchParams.get("z") || ""
  let mobileNumber

  if (isPrivate && isBrowser()) {
    if (!hasSignedInUser()) {
      // If there is no firebase:authUser in session storage then go to the input number page

      // This function is used for the proof-of-payment page. They get a link from the email with a Zendesk ID in the URL.
      // This will find the mobile number associated with the ticket and save the number to authState.
      const getOrderDetails = async () => {
        let response = await axios.post(GATSBY_GET_ORDER_DETAILS_WEBHOOK, {
          zendeskId,
        })
        mobileNumber = response.data.mobileNumber
        if (mobileNumber) {
          await authDispatch({
            type: "SAVE_DETAILS",
            payload: {
              mobileNumber: mobileNumber.slice(1, 11),
            },
          })
          await navigate(
            `/epharmacy/verify-otp${!!zendeskId && `?z=${zendeskId}`}`
          )
        }
      }
      if (!!zendeskId) getOrderDetails()
      else navigate("/")
      return <Loading />
    }
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute
