import { isBrowser } from "services/general"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    // Looks for the key with firebase:authUser
    const USER = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]

    return USER
  }
}
